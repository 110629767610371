import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Button, PaginationProps, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import qs from 'qs';

import BrandsService from '../../services/brands';
import { BrandsDialog } from './components/Dialog';
import { SearchFilters } from './components/SearchFilters';
import { ConfirmationModal } from '../../components/confirmationModal/ConfirmationModal';
import { useBrandsColumns } from './components/useBrandsColumns';
import styles from './styles.module.scss';

export const BrandsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [brands, setBrands] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [brand, setBrand] = useState<Record<string, string>>(null);
  const [total, setTotal] = useState();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    navigate({ pathname: location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching } = useQuery(['brands', location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return BrandsService.getBrands({ page, limit, ...params }, { signal });
  });

  const showModal = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setSelectedBrand(null);
  };

  const columnsParams = useMemo(
    () => ({ setBrand, showModal, setSelectedBrand, setConfirmationModal }),
    [setBrand, showModal, setSelectedBrand, setConfirmationModal]
  );

  const brandsColumns = useBrandsColumns(columnsParams);

  useEffect(() => {
    if (data) {
      setBrands(data?.results);
      setTotal(data?.count);
    }
  }, [data]);

  const getData = useCallback(() => {
    queryClient.refetchQueries('brands');
  }, [queryClient]);

  const handleDelete = (id: string | number) => {
    BrandsService.deleteBrand(id)
      .then((res) => {
        getData();
        handleCloseModal();
      })
      .catch((err) => setError('Something went wrong'));
  };

  return (
    <div className={styles.layout_content}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <SearchFilters />
        <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            showModal();
            setBrand(null);
          }}
        >
          Brand
        </Button>
      </div>
      <Table
        columns={brandsColumns}
        dataSource={brands}
        loading={isFetching}
        bordered
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
      />
      <BrandsDialog open={open} companyData={brand} handleCancel={handleCancel} fetchData={getData} />
      <ConfirmationModal
        open={openConfirmationModal}
        close={handleCloseModal}
        title={``}
        textError={error}
        description={`Are you sure you want to delete ${selectedBrand?.name_val} ?`}
        onSubmit={() => handleDelete(selectedBrand?.id)}
      />
    </div>
  );
};
